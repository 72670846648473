import React from 'react'
import styled from 'styled-components';
import Layout from '../../components/layout'
import { Container, Row, Col } from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const TeamImage = styled.div`
  width: 86px;
  height: 96px;
  background: url('/images/team-bg.png') center center no-repeat;
  background-size: 86px 96px;
  @media
  (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    background: url('/images/team-bg@2x.png') center center no-repeat;
    background-size: 86px 96px;
  }
  margin: 32px auto;
  @media (min-width: 576px) {
    width: 172px;
    height: 192px;
    background: url('/images/team-bg.png') center center no-repeat;
    background-size: 172px 192px;
    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      background: url('/images/team-bg@2x.png') center center no-repeat;
      background-size: 172px 192px;
    }
	  margin: 60px auto;
  }
`;

const TeamTag = styled.span`
	font-size: 16px;
  @media (min-width: 576px) {
    font-size: 24px;
  }
	display: inline-block;
	margin: 1em 0.5em;
`;

const TeamDescription = styled.p`
	max-width: 800px;
	margin: 0 auto 60px;
	color: #240F77;
	text-align: center;
	font-size: 14px;
  @media (min-width: 576px) {
    font-size: 20px;
  }
`;

const JobDescriptionsTitle = styled.h2`
  margin-top: 0px;
  margin-bottom: 0;
  font-size: 14px;
  @media (min-width: 576px) {
    margin-top: 60px;
    font-size: 24px;
  }
	line-height: 32px;
	color: #828282;
`;
const TeamSection = styled.section`
  min-height: 624px;
  @media (min-width: 576px) {
    min-height: 1080px;
  }
	overflow: auto;
`;
const SectionTitle = styled.h3`
  text-align: center;
	font-size: 16px;
  margin: 32px 0 30px 0;
  @media (min-width: 576px) {
    font-size: 32px;
    margin: 110px 0 60px 0;
  }
  font-weight: bold;
  line-height: 39px;
  color: #240F77;
`;

const PersonTitle = styled.div`
	max-width: 200px;
	font-style: normal;
	font-weight: 500;
	font-size: 11px;
  line-height: 10px;
  margin: 16px 0 8px 0;
  @media (min-width: 576px) {
    font-size: 14px;
    line-height: 13px;
    margin: 24px 0 10px 0;
  }
  color: #828282;
`;

  const PersonName = styled.div`
  max-width: 200px;
  font-weight: bold;
  font-size: 16px;
  line-height: 15px;
  @media (min-width: 576px) {
    font-size: 20px;
    line-height: 19px;
  }
  margin-bottom: 16px;
	color: #240F77;
`
const PersonBio = styled.div`
	max-width: 200px;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 15px;
  height: 108px;
  @media (min-width: 576px) {
    font-size: 12px;
    line-height: 18px;
    height: 108px;
  }

	color: #222222;
	opacity: 0.5;
`;
const COLORS = [
	'#EF8160',
	'#00CF9C',
	'#775AEC',
	'#4CD2E5'
];
const List = styled.ul`
	margin: 0 0 120px 0;
	padding: 0;
	list-style: none;
`;

const ListItem = styled.li`
	color: #240F77;
	border-bottom: 1px solid #240F77;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 32px;

	a {
		display: block;
		width: 100%;
		background: url('/images/list-item.png') no-repeat right;
		color: #240F77;
		padding: 15px 32px 10px 0;
	}
	a:hover, a:active {
		color: #775AEC;
		background: url('/images/list-item_hover.png') no-repeat right;
		text-decoration: none;
	}

	@media
	(-webkit-min-device-pixel-ratio: 2),
	(min-resolution: 192dpi) {

		a {
			background: url('/images/list-item@2x.png') no-repeat right;
			background-size: 24px 24px;
		}
		a:hover, a:active {
			background: url('/images/list-item_hover@2x.png') no-repeat right;
			background-size: 24px 24px;
		}
	}
  @media (min-width: 576px) {
    height: 84px;
    font-size: 16px;

    a {
      display: block;
      width: 100%;
      background: url('/images/list-item.png') no-repeat right;
      color: #240F77;
      padding: 15px 58px 10px 0;
    }
    a:hover, a:active {
      color: #775AEC;
      background: url('/images/list-item_hover.png') no-repeat right;
      text-decoration: none;
    }
    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      a {
        background: url('/images/list-item@2x.png') no-repeat right;
        background-size: 48px 48px;
      }
      a:hover, a:active {
        background: url('/images/list-item_hover@2x.png') no-repeat right;
        background-size: 48px 48px;
      }
    }
  }

`;

const ListItemTitle = styled.h3`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
	margin: 0;
	font-weight: 700;
	font-size: 14px;
  @media (min-width: 576px) {
    font-size: 20px;
	  line-height: 32px;
  }
`;

const nl2br = ((text) => text.split('\n').map((item, key) =>
  <span key={key}>
    {item}
    <br/>
  </span>
));

class TeamContainer extends React.Component {
  render() {
    const { siteContents, people, language, jobDescriptions } = this.props

    return (
      <Layout
				location={this.props.location}
        language={language}
        siteContents={siteContents}
			>
        <TeamSection>
					<Container>
           <SectionTitle>{siteContents.menu4}</SectionTitle>
						<TeamImage />
						<div style={{ textAlign: 'center'}}>
							{siteContents.menu4Tags.map((tag, i) => (
								<TeamTag key={i} style={{ color: COLORS[i % 4] }}>{tag}</TeamTag>
							))}
						</div>

            <TeamDescription>
              {siteContents.menu4Description.menu4Description}
            </TeamDescription>

						<Row>
							{people.map((person, i) => (
								<Col key={i} xs={6} sm={6} lg={3}>
									<img
                    src={person.image.file.url}
                    srcSet={person.image.sizes.srcSet}
                    style={{ width: '100%' }}
                  />
									<PersonTitle>{person.title}</PersonTitle>
									<PersonName>{person.name}</PersonName>
									<PersonBio>{person.shortBio && nl2br(person.shortBio.shortBio)}
                  </PersonBio>
								</Col>
							))}
							</Row>

            {jobDescriptions && jobDescriptions.length > 0 && (
              <>
                <JobDescriptionsTitle>{siteContents.menu4JdTitle}</JobDescriptionsTitle>
                <List>
                  {jobDescriptions.map((jd, i) => (
                    <ListItem key={i}>
                      <a href={jd.url} target="_blank">
                        <ListItemTitle>{jd.position}</ListItemTitle>
                        {jd.description.description}
                      </a>
                    </ListItem>
                  ))}
                </List>
              </>
            )}

					</Container>
				</TeamSection>
      </Layout>
    )
  }
}

export default TeamContainer
